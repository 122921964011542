<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>

    <b-tabs>
      <b-tab active title="English">
        <b-card title="Contact Us Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description">
                <ckeditor v-model="page.description"></ckeditor>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Head Office">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Title">
                <b-form-input
                  v-model="page.headOffice.title"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Address">
                <b-form-input
                  v-model="page.headOffice.address.description"
                  placeholder="address"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Direction Link">
                <b-form-input
                  v-model="page.headOffice.address.directionLink"
                  placeholder="URL"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Phone No.">
                <b-form-input v-model="page.headOffice.phone"></b-form-input>
              </b-form-group>
              <b-form-group label="Email">
                <b-form-input v-model="page.headOffice.email"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Information & Booking Queries">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Title">
                <b-form-input
                  v-model="page.bookingQueries.title"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form
                  class="repeater-form"
                  @submit.prevent="repeatbookingQueries(null)"
                >
                  <b-row
                    v-for="(queries, index) in page.bookingQueries.queryList"
                    :key="index"
                    ref="queryItems"
                  >
                    <b-col cols="12">
                      <b-form-group :label="'Booking Query- ' + (index + 1)">
                        <b-row>
                          <b-col cols="10">
                            <b-form-group>
                              <b-form-input
                                v-model="
                                  page.bookingQueries.queryList[index].title
                                "
                                placeholder="Section Title"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col lg="2" md="3" class="mb-50">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              @click="removeBookingQuery(null, index)"
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                              <span>Remove</span>
                            </b-button>
                          </b-col>
                          <b-col cols="12">
                            <b-form-group>
                              <ckeditor
                                v-model="
                                  page.bookingQueries.queryList[index]
                                    .description
                                "
                              ></ckeditor>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="mt-1"
                      ><b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeatbookingQueries(null)"
                      >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>{{
                          page.bookingQueries.queryList.length === 0
                            ? "Add Query"
                            : "Add New Query"
                        }}</span>
                      </b-button></b-col
                    >
                  </b-row>
                </b-form>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Resort Manager Details">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Title">
                <b-form-input
                  v-model="page.contactDetails.ResortManagerList.title"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Description">
                <b-form-input
                  v-model="page.contactDetails.ResortManagerList.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="align-self-center">
              <ComponentMediaSelector
              :params="{ lang: null }"
                :isMultiple="false"
                @mediaSelected="handleMediaSelectResort"
              />
            </b-col>
            <b-col md="3">
              <div
                class="text-center my-1"
                v-if="page.contactDetails.ResortManagerList.icon"
              >
                <b-img
                  v-bind="mainProps"
                  :src="page.contactDetails.ResortManagerList.icon"
                ></b-img>
                <a
                  @click="page.contactDetails.ResortManagerList.icon = ''"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Booking COunter Details">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Title">
                <b-form-input
                  v-model="page.contactDetails.BookingCounterList.title"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Description">
                <b-form-input
                  v-model="page.contactDetails.BookingCounterList.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="align-self-center">
              <ComponentMediaSelector
              :params="{ lang: null }"
                :isMultiple="false"
                @mediaSelected="handleMediaSelectBooking"
              />
            </b-col>
            <b-col md="3">
              <div
                class="text-center my-1"
                v-if="page.contactDetails.BookingCounterList.icon"
              >
                <b-img
                  v-bind="mainProps"
                  :src="page.contactDetails.BookingCounterList.icon"
                ></b-img>
                <a
                  @click="page.contactDetails.BookingCounterList.icon = ''"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Regional Office Details">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Title">
                <b-form-input
                  v-model="page.contactDetails.RegionalOfficeList.title"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Description">
                <b-form-input
                  v-model="page.contactDetails.RegionalOfficeList.description"
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="align-self-center">
              <ComponentMediaSelector
              :params="{ lang: null }"
                :isMultiple="false"
                @mediaSelected="handleMediaSelectRegional"
              />
            </b-col>
            <b-col md="3">
              <div
                class="text-center my-1"
                v-if="page.contactDetails.RegionalOfficeList.icon"
              >
                <b-img
                  v-bind="mainProps"
                  :src="page.contactDetails.RegionalOfficeList.icon"
                ></b-img>
                <a
                  @click="page.contactDetails.RegionalOfficeList.icon = ''"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab title="Hindi">
        <b-card title="Contact Us Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.hi.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description">
                <ckeditor v-model="page.translation.hi.description"></ckeditor>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Head Office">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Title">
                <b-form-input
                  v-model="page.translation.hi.headOffice.title"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Address">
                <b-form-input
                  v-model="page.translation.hi.headOffice.address.description"
                  placeholder="address"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Direction Link">
                <b-form-input
                  v-model="page.translation.hi.headOffice.address.directionLink"
                  placeholder="URL"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Phone No.">
                <b-form-input
                  v-model="page.translation.hi.headOffice.phone"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Email">
                <b-form-input
                  v-model="page.translation.hi.headOffice.email"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Information & Booking Queries">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Title">
                <b-form-input
                  v-model="page.translation.hi.bookingQueries.title"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form
                  class="repeater-form"
                  @submit.prevent="repeatbookingQueries('hi')"
                >
                  <b-row
                    v-for="(queries, index) in page.translation.hi
                      .bookingQueries.queryList"
                    :key="index"
                    ref="queryItems"
                  >
                    <b-col cols="12">
                      <b-form-group :label="'Booking Query- ' + (index + 1)">
                        <b-row>
                          <b-col cols="10">
                            <b-form-group>
                              <b-form-input
                                v-model="
                                  page.translation.hi.bookingQueries.queryList[
                                    index
                                  ].title
                                "
                                placeholder="Section Title"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col lg="2" md="3" class="mb-50">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              @click="removeBookingQuery('hi', index)"
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                              <span>Remove</span>
                            </b-button>
                          </b-col>
                          <b-col cols="12">
                            <b-form-group>
                              <ckeditor
                                v-model="
                                  page.translation.hi.bookingQueries.queryList[
                                    index
                                  ].description
                                "
                              ></ckeditor>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="mt-1"
                      ><b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeatbookingQueries('hi')"
                      >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>{{
                          page.translation.hi.bookingQueries.queryList
                            .length === 0
                            ? "Add Query"
                            : "Add New Query"
                        }}</span>
                      </b-button></b-col
                    >
                  </b-row>
                </b-form>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Resort Manager Details">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Title">
                <b-form-input
                  v-model="
                    page.translation.hi.contactDetails.ResortManagerList.title
                  "
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Description">
                <b-form-input
                  v-model="
                    page.translation.hi.contactDetails.ResortManagerList
                      .description
                  "
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="align-self-center">
              <ComponentMediaSelector
              :params="{lang: 'hi'}"
                :isMultiple="false"
                @mediaSelected="handleMediaSelectResort"
              />
            </b-col>
            <b-col md="3">
              <div
                class="text-center my-1"
                v-if="page.translation.hi.contactDetails.ResortManagerList.icon"
              >
                <b-img
                  v-bind="mainProps"
                  :src="
                    page.translation.hi.contactDetails.ResortManagerList.icon
                  "
                ></b-img>
                <a
                  @click="
                    page.translation.hi.contactDetails.ResortManagerList.icon =
                      ''
                  "
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Booking COunter Details">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Title">
                <b-form-input
                  v-model="
                    page.translation.hi.contactDetails.BookingCounterList.title
                  "
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Description">
                <b-form-input
                  v-model="
                    page.translation.hi.contactDetails.BookingCounterList
                      .description
                  "
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="align-self-center">
              <ComponentMediaSelector
              :params="{ lang: 'hi' }"
                :isMultiple="false"
                @mediaSelected="handleMediaSelectBooking"
              />
            </b-col>
            <b-col md="3">
              <div
                class="text-center my-1"
                v-if="
                  page.translation.hi.contactDetails.BookingCounterList.icon
                "
              >
                <b-img
                  v-bind="mainProps"
                  :src="
                    page.translation.hi.contactDetails.BookingCounterList.icon
                  "
                ></b-img>
                <a
                  @click="
                    page.translation.hi.contactDetails.BookingCounterList.icon =
                      ''
                  "
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Regional Office Details">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Title">
                <b-form-input
                  v-model="
                    page.translation.hi.contactDetails.RegionalOfficeList.title
                  "
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Description">
                <b-form-input
                  v-model="
                    page.translation.hi.contactDetails.RegionalOfficeList
                      .description
                  "
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="align-self-center">
              <ComponentMediaSelector
              :params="{ lang: 'hi' }"
                :isMultiple="false"
                @mediaSelected="handleMediaSelectRegional"
              />
            </b-col>
            <b-col md="3">
              <div
                class="text-center my-1"
                v-if="
                  page.translation.hi.contactDetails.RegionalOfficeList.icon
                "
              >
                <b-img
                  v-bind="mainProps"
                  :src="
                    page.translation.hi.contactDetails.RegionalOfficeList.icon
                  "
                ></b-img>
                <a
                  @click="
                    page.translation.hi.contactDetails.RegionalOfficeList.icon =
                      ''
                  "
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab title="Marathi">
        <b-card title="Contact Us Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.translation.mr.pageTitle"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Description">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Description">
                <ckeditor v-model="page.translation.mr.description"></ckeditor>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Head Office">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Title">
                <b-form-input
                  v-model="page.translation.mr.headOffice.title"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Address">
                <b-form-input
                  v-model="page.translation.mr.headOffice.address.description"
                  placeholder="address"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Direction Link">
                <b-form-input
                  v-model="page.translation.mr.headOffice.address.directionLink"
                  placeholder="URL"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Phone No.">
                <b-form-input
                  v-model="page.translation.mr.headOffice.phone"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Email">
                <b-form-input
                  v-model="page.translation.mr.headOffice.email"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Information & Booking Queries">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Title">
                <b-form-input
                  v-model="page.translation.mr.bookingQueries.title"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <b-form
                  class="repeater-form"
                  @submit.prevent="repeatbookingQueries('mr')"
                >
                  <b-row
                    v-for="(queries, index) in page.translation.mr
                      .bookingQueries.queryList"
                    :key="index"
                    ref="queryItems"
                  >
                    <b-col cols="12">
                      <b-form-group :label="'Booking Query- ' + (index + 1)">
                        <b-row>
                          <b-col cols="10">
                            <b-form-group>
                              <b-form-input
                                v-model="
                                  page.translation.mr.bookingQueries.queryList[
                                    index
                                  ].title
                                "
                                placeholder="Section Title"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col lg="2" md="3" class="mb-50">
                            <b-button
                              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                              variant="outline-danger"
                              @click="removeBookingQuery('mr', index)"
                            >
                              <feather-icon icon="XIcon" class="mr-25" />
                              <span>Remove</span>
                            </b-button>
                          </b-col>
                          <b-col cols="12">
                            <b-form-group>
                              <ckeditor
                                v-model="
                                  page.translation.mr.bookingQueries.queryList[
                                    index
                                  ].description
                                "
                              ></ckeditor>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="mt-1"
                      ><b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        @click="repeatbookingQueries('mr')"
                      >
                        <feather-icon icon="PlusIcon" class="mr-25" />
                        <span>{{
                          page.translation.mr.bookingQueries.queryList
                            .length === 0
                            ? "Add Query"
                            : "Add New Query"
                        }}</span>
                      </b-button></b-col
                    >
                  </b-row>
                </b-form>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Resort Manager Details">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Title">
                <b-form-input
                  v-model="
                    page.translation.mr.contactDetails.ResortManagerList.title
                  "
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Description">
                <b-form-input
                  v-model="
                    page.translation.mr.contactDetails.ResortManagerList
                      .description
                  "
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="align-self-center">
              <ComponentMediaSelector
              :params="{lang: 'mr'}"
                :isMultiple="false"
                @mediaSelected="handleMediaSelectResort"
              />
            </b-col>
            <b-col md="3">
              <div
                class="text-center my-1"
                v-if="page.translation.mr.contactDetails.ResortManagerList.icon"
              >
                <b-img
                  v-bind="mainProps"
                  :src="
                    page.translation.mr.contactDetails.ResortManagerList.icon
                  "
                ></b-img>
                <a
                  @click="
                    page.translation.mr.contactDetails.ResortManagerList.icon =
                      ''
                  "
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Booking COunter Details">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Title">
                <b-form-input
                  v-model="
                    page.translation.mr.contactDetails.BookingCounterList.title
                  "
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Description">
                <b-form-input
                  v-model="
                    page.translation.mr.contactDetails.BookingCounterList
                      .description
                  "
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="align-self-center">
              <ComponentMediaSelector
              :params="{ lang: 'mr' }"
                :isMultiple="false"
                @mediaSelected="handleMediaSelectBooking"
              />
            </b-col>
            <b-col md="3">
              <div
                class="text-center my-1"
                v-if="
                  page.translation.mr.contactDetails.BookingCounterList.icon
                "
              >
                <b-img
                  v-bind="mainProps"
                  :src="
                    page.translation.mr.contactDetails.BookingCounterList.icon
                  "
                ></b-img>
                <a
                  @click="
                    page.translation.mr.contactDetails.BookingCounterList.icon =
                      ''
                  "
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Regional Office Details">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Title">
                <b-form-input
                  v-model="
                    page.translation.mr.contactDetails.RegionalOfficeList.title
                  "
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Description">
                <b-form-input
                  v-model="
                    page.translation.mr.contactDetails.RegionalOfficeList
                      .description
                  "
                  placeholder="Description"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="2" class="align-self-center">
              <ComponentMediaSelector
              :params="{ lang: 'mr' }"
                :isMultiple="false"
                @mediaSelected="handleMediaSelectRegional"
              />
            </b-col>
            <b-col md="3">
              <div
                class="text-center my-1"
                v-if="
                  page.translation.mr.contactDetails.RegionalOfficeList.icon
                "
              >
                <b-img
                  v-bind="mainProps"
                  :src="
                    page.translation.mr.contactDetails.RegionalOfficeList.icon
                  "
                ></b-img>
                <a
                  @click="
                    page.translation.mr.contactDetails.RegionalOfficeList.icon =
                      ''
                  "
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>

    <b-card>
      <b-row>
        <b-col cols="6">
          <b-button size="lg" @click="saveDraft" block variant="outline-info"
            >Create Draft</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button size="lg" @click="savePage" block variant="primary"
            >Save Page</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BImg,
  BAlert,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { heightFade } from "@core/directives/animations";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    BAlert,
  },
  data() {
    return {
      isDraftAvailable: false,
      tenders: [],
      mainProps: {
        width: 100,
        height: 100,
      },
      page: {
        pageTitle: "",
        description: "",
        headOffice: {
          title: "",
          address: {
            description: "",
            directionLink: "",
          },
          phone: "",
          email: "",
        },
        bookingQueries: {
          title: "",
          queryList: [],
        },
        contactDetails: {
          ResortManagerList: {
            title: "",
            url: "",
            description: "",
            icon: "",
            resortManagers: [],
          },
          RegionalOfficeList: {
            title: "",
            url: "",
            description: "",
            icon: "",
            regionalOffices: [],
          },
          BookingCounterList: {
            title: "",
            url: "",
            description: "",
            icon: "",
            bookingCounters: [],
          },
        },
        translation: {
          hi: {
            pageTitle: "",
            description: "",
            headOffice: {
              title: "",
              address: {
                description: "",
                directionLink: "",
              },
              phone: "",
              email: "",
            },
            bookingQueries: {
              title: "",
              queryList: [],
            },
            contactDetails: {
              ResortManagerList: {
                title: "",
                url: "",
                description: "",
                icon: "",
                resortManagers: [],
              },
              RegionalOfficeList: {
                title: "",
                url: "",
                description: "",
                icon: "",
                regionalOffices: [],
              },
              BookingCounterList: {
                title: "",
                url: "",
                description: "",
                icon: "",
                bookingCounters: [],
              },
            },
          },
          mr: {
            pageTitle: "",
            description: "",
            headOffice: {
              title: "",
              address: {
                description: "",
                directionLink: "",
              },
              phone: "",
              email: "",
            },
            bookingQueries: {
              title: "",
              queryList: [],
            },
            contactDetails: {
              ResortManagerList: {
                title: "",
                url: "",
                description: "",
                icon: "",
                resortManagers: [],
              },
              RegionalOfficeList: {
                title: "",
                url: "",
                description: "",
                icon: "",
                regionalOffices: [],
              },
              BookingCounterList: {
                title: "",
                url: "",
                description: "",
                icon: "",
                bookingCounters: [],
              },
            },
          },
        },
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    handleMediaSelectResort(mediaObject) {
      if (mediaObject.status) {
          if(mediaObject.params.lang) {
          this.page.translation[mediaObject.params.lang].contactDetails.ResortManagerList.icon = mediaObject.media.src;
          } else {
          this.page.contactDetails.ResortManagerList.icon = mediaObject.media.src;
          }
      }
    },
    handleMediaSelectBooking(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
          this.page.translation[mediaObject.params.lang].contactDetails.BookingCounterList.icon =
          mediaObject.media.src;
          } else {
          this.page.contactDetails.BookingCounterList.icon =
          mediaObject.media.src;
          }
      }
    },
    handleMediaSelectRegional(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
          this.page.translation[mediaObject.params.lang].contactDetails.RegionalOfficeList.icon =
          mediaObject.media.src;
          } else {
          this.page.contactDetails.RegionalOfficeList.icon =
          mediaObject.media.src;
          }
      }
    },
   
    repeatbookingQueries(lang = undefined) {
      if (lang) {
        this.page.translation[lang].bookingQueries.queryList.push({
          title: "",
          description: "",
        });
      } else {
        this.page.bookingQueries.queryList.push({
          title: "",
          description: "",
        });
      }
    },
    removeBookingQuery(lang = undefined, index) {
      if (lang) {
        this.page.translation[lang].bookingQueries.queryList.splice(index, 1);
      } else {
        this.page.bookingQueries.queryList.splice(index, 1);
      }
    },
    saveDraft() {
      localStorage.setItem("contact-page-data", JSON.stringify(this.page));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const page = localStorage.getItem("contact-page-data");
      this.page = JSON.parse(page);
      console.log(this.page);
    },
    removeDraft() {
      localStorage.removeItem("contact-page-data");
      this.isDraftAvailable = false;
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/contactUs/get`)
        .then((response) => {
          if (response.data.length > 0) {
            this.page = response.data[0];
            const { translation } = response.data[0];

            this.page.translation = {
              hi: {
                pageTitle:
                  translation && translation.hi.pageTitle
                    ? translation.hi.pageTitle
                    : "",
                description:
                  translation && translation.hi.description
                    ? translation.hi.description
                    : "",
                headOffice: {
                  title:
                    translation &&
                    translation.hi.headOffice &&
                    translation.hi.headOffice.title
                      ? translation.hi.headOffice.title
                      : "",
                  address: {
                    description:
                      translation &&
                      translation.hi.headOffice &&
                      translation.hi.headOffice.address &&
                      translation.hi.headOffice.address.description
                        ? translation.hi.headOffice.address.description
                        : "",
                    directionLink:
                      translation &&
                      translation.hi.headOffice &&
                      translation.hi.headOffice.address &&
                      translation.hi.headOffice.address.directionLink
                        ? translation.hi.headOffice.address.directionLink
                        : "",
                  },
                  phone:
                    translation && translation.hi.phone
                      ? translation.hi.phone
                      : "",
                  email:
                    translation && translation.hi.email
                      ? translation.hi.email
                      : "",
                },
                bookingQueries: {
                  title:
                    translation &&
                    translation.hi.bookingQueries &&
                    translation.hi.bookingQueries.title
                      ? translation.hi.bookingQueries.title
                      : "",
                  queryList:
                    translation &&
                    translation.hi.bookingQueries &&
                    translation.hi.bookingQueries.queryList
                      ? translation.hi.bookingQueries.queryList
                      : [],
                },
                contactDetails: {
                  ResortManagerList: {
                    title:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.ResortManagerList &&
                      translation.hi.contactDetails.ResortManagerList.title
                        ? translation.hi.contactDetails.ResortManagerList.title
                        : "",
                    url:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.ResortManagerList &&
                      translation.hi.contactDetails.ResortManagerList.url
                        ? translation.hi.contactDetails.ResortManagerList.url
                        : "",
                    description:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.ResortManagerList &&
                      translation.hi.contactDetails.ResortManagerList
                        .description
                        ? translation.hi.contactDetails.ResortManagerList
                            .description
                        : "",
                    icon:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.ResortManagerList &&
                      translation.hi.contactDetails.ResortManagerList.icon
                        ? translation.hi.contactDetails.ResortManagerList.icon
                        : "",
                    resortManagers:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.ResortManagerList &&
                      translation.hi.contactDetails.ResortManagerList
                        .resortManagers
                        ? translation.hi.contactDetails.ResortManagerList
                            .resortManagers
                        : [],
                  },
                  RegionalOfficeList: {
                    title:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.RegionalOfficeList &&
                      translation.hi.contactDetails.RegionalOfficeList.title
                        ? translation.hi.contactDetails.RegionalOfficeList.title
                        : "",
                    url:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.RegionalOfficeList &&
                      translation.hi.contactDetails.RegionalOfficeList.url
                        ? translation.hi.contactDetails.RegionalOfficeList.url
                        : "",
                    description:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.RegionalOfficeList &&
                      translation.hi.contactDetails.RegionalOfficeList
                        .description
                        ? translation.hi.contactDetails.RegionalOfficeList
                            .description
                        : "",
                    icon:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.RegionalOfficeList &&
                      translation.hi.contactDetails.RegionalOfficeList.icon
                        ? translation.hi.contactDetails.RegionalOfficeList.icon
                        : "",
                    resortManagers:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.RegionalOfficeList &&
                      translation.hi.contactDetails.RegionalOfficeList
                        .resortManagers
                        ? translation.hi.contactDetails.RegionalOfficeList
                            .resortManagers
                        : [],
                  },
                  BookingCounterList: {
                    title:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.BookingCounterList &&
                      translation.hi.contactDetails.BookingCounterList.title
                        ? translation.hi.contactDetails.BookingCounterList.title
                        : "",
                    url:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.BookingCounterList &&
                      translation.hi.contactDetails.BookingCounterList.url
                        ? translation.hi.contactDetails.BookingCounterList.url
                        : "",
                    description:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.BookingCounterList &&
                      translation.hi.contactDetails.BookingCounterList
                        .description
                        ? translation.hi.contactDetails.BookingCounterList
                            .description
                        : "",
                    icon:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.BookingCounterList &&
                      translation.hi.contactDetails.BookingCounterList.icon
                        ? translation.hi.contactDetails.BookingCounterList.icon
                        : "",
                    resortManagers:
                      translation &&
                      translation.hi.contactDetails &&
                      translation.hi.contactDetails.BookingCounterList &&
                      translation.hi.contactDetails.BookingCounterList
                        .resortManagers
                        ? translation.hi.contactDetails.BookingCounterList
                            .resortManagers
                        : [],
                  },
                },
              },
              mr: {
                pageTitle:
                  translation && translation.mr.pageTitle
                    ? translation.mr.pageTitle
                    : "",
                description:
                  translation && translation.mr.description
                    ? translation.mr.description
                    : "",
                headOffice: {
                  title:
                    translation &&
                    translation.mr.headOffice &&
                    translation.mr.headOffice.title
                      ? translation.mr.headOffice.title
                      : "",
                  address: {
                    description:
                      translation &&
                      translation.mr.headOffice &&
                      translation.mr.headOffice.address &&
                      translation.mr.headOffice.address.description
                        ? translation.mr.headOffice.address.description
                        : "",
                    directionLink:
                      translation &&
                      translation.mr.headOffice &&
                      translation.mr.headOffice.address &&
                      translation.mr.headOffice.address.directionLink
                        ? translation.mr.headOffice.address.directionLink
                        : "",
                  },
                  phone:
                    translation && translation.mr.phone
                      ? translation.mr.phone
                      : "",
                  email:
                    translation && translation.mr.email
                      ? translation.mr.email
                      : "",
                },
                bookingQueries: {
                  title:
                    translation &&
                    translation.mr.bookingQueries &&
                    translation.mr.bookingQueries.title
                      ? translation.mr.bookingQueries.title
                      : "",
                  queryList:
                    translation &&
                    translation.mr.bookingQueries &&
                    translation.mr.bookingQueries.queryList
                      ? translation.mr.bookingQueries.queryList
                      : [],
                },
                contactDetails: {
                  ResortManagerList: {
                    title:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.ResortManagerList &&
                      translation.mr.contactDetails.ResortManagerList.title
                        ? translation.mr.contactDetails.ResortManagerList.title
                        : "",
                    url:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.ResortManagerList &&
                      translation.mr.contactDetails.ResortManagerList.url
                        ? translation.mr.contactDetails.ResortManagerList.url
                        : "",
                    description:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.ResortManagerList &&
                      translation.mr.contactDetails.ResortManagerList
                        .description
                        ? translation.mr.contactDetails.ResortManagerList
                            .description
                        : "",
                    icon:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.ResortManagerList &&
                      translation.mr.contactDetails.ResortManagerList.icon
                        ? translation.mr.contactDetails.ResortManagerList.icon
                        : "",
                    resortManagers:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.ResortManagerList &&
                      translation.mr.contactDetails.ResortManagerList
                        .resortManagers
                        ? translation.mr.contactDetails.ResortManagerList
                            .resortManagers
                        : [],
                  },
                  RegionalOfficeList: {
                    title:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.RegionalOfficeList &&
                      translation.mr.contactDetails.RegionalOfficeList.title
                        ? translation.mr.contactDetails.RegionalOfficeList.title
                        : "",
                    url:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.RegionalOfficeList &&
                      translation.mr.contactDetails.RegionalOfficeList.url
                        ? translation.mr.contactDetails.RegionalOfficeList.url
                        : "",
                    description:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.RegionalOfficeList &&
                      translation.mr.contactDetails.RegionalOfficeList
                        .description
                        ? translation.mr.contactDetails.RegionalOfficeList
                            .description
                        : "",
                    icon:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.RegionalOfficeList &&
                      translation.mr.contactDetails.RegionalOfficeList.icon
                        ? translation.mr.contactDetails.RegionalOfficeList.icon
                        : "",
                    resortManagers:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.RegionalOfficeList &&
                      translation.mr.contactDetails.RegionalOfficeList
                        .resortManagers
                        ? translation.mr.contactDetails.RegionalOfficeList
                            .resortManagers
                        : [],
                  },
                  BookingCounterList: {
                    title:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.BookingCounterList &&
                      translation.mr.contactDetails.BookingCounterList.title
                        ? translation.mr.contactDetails.BookingCounterList.title
                        : "",
                    url:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.BookingCounterList &&
                      translation.mr.contactDetails.BookingCounterList.url
                        ? translation.mr.contactDetails.BookingCounterList.url
                        : "",
                    description:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.BookingCounterList &&
                      translation.mr.contactDetails.BookingCounterList
                        .description
                        ? translation.mr.contactDetails.BookingCounterList
                            .description
                        : "",
                    icon:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.BookingCounterList &&
                      translation.mr.contactDetails.BookingCounterList.icon
                        ? translation.mr.contactDetails.BookingCounterList.icon
                        : "",
                    resortManagers:
                      translation &&
                      translation.mr.contactDetails &&
                      translation.mr.contactDetails.BookingCounterList &&
                      translation.mr.contactDetails.BookingCounterList
                        .resortManagers
                        ? translation.mr.contactDetails.BookingCounterList
                            .resortManagers
                        : [],
                  },
                },
              },
            };
            console.log(response.data[0]);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    savePage() {
      console.log(this.page);
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/pages/contactUs/save`, this.page, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.makeToast(
            "success",
            "Success",
            `${this.page.pageTitle} has been added successfully!`
          );
        })
        .catch((error) => {
          this.makeToast("danger", "Error", "Error while adding the tender!");
          console.log(error.response);
        });
    },
  },
  mounted() {
    if (localStorage.getItem("contact-page-data")) {
      this.isDraftAvailable = true;
    }
    this.getPage();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>